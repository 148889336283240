import React, { useState, useEffect, useRef } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';
import '../components/styles/index.css';

const citation = [
  {
    sentence: 'Les événements de notre vie changent selon la nature de nos pensées. <br />Quand nous changeons nos pensées, nous changeons notre vie.',
    sign: 'FZ ALAMI'
  },
  {
    sentence: 'Ce qui est créé par l’esprit est plus vivant que la matière.',
    sign: 'Charles Baudelaire'
  }
];

const IndexPage = () => {
  const { allContentfulHome: { edges: [data] } } = useStaticQuery(graphql`
    query QueryIndex {
      allContentfulHome {
        edges {
          node {
            id
            profilDescription {
              id
              childMarkdownRemark {
                html
              }
            }
            profil {
              id
              file {
                url
                fileName
              }
              description
            }
            certification {
              file {
                url
                fileName
                contentType
              }
            }
          }
        }
      }
    }
  `);
  const [citatationKey, setCitationKey] = useState(0);
  const scrollTarget = useRef();
  useEffect(() => {
    const { length: citationLength } = citation;
    const interval = setInterval(() => setCitationKey(index => ((citationLength - 1) === index) ? 0 : (index + 1)), 7_000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const { sentence, sign } = citation[citatationKey];
  const handleScrollToTarget = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: scrollTarget.current.getBoundingClientRect().top + window.pageYOffset - 70,
        behavior: 'smooth'
      });
    }
  };
  return (
    <Layout>
      <Seo title='Home' />
      <div id='homeHeader'>
        <div className='content container'>
          <div className='citations'>
            <h2>
              <span dangerouslySetInnerHTML={{ __html: sentence }} /> <i className='fa fa-quote-right' />
            </h2>
            <p className='sign'>{sign}</p>
          </div>
          <div className='btnHeader'>
            <Link to='/hypnose-seance'><button className='white'>En savoir plus</button></Link>
            <Link to='/contact'><button className='blue'>Me contacter</button></Link>
          </div>
        </div>
        <div id='transition' onClick={handleScrollToTarget} role='button' tabIndex={0} aria-label='lift' aria-hidden='true'><i className='fa fa-angle-down' /></div>
      </div>

      <div className='container'>
        <div className='division'>
          <h2 className='title'>Francine Lampin</h2>
          <div className='row'>
            <div className='col-md-12'>
              <StaticImage src='../img/FrancineLampin.jpg' placeholder='blurred' alt='francine' className='stamp' width={370} height={247} />
              <span className='profil-description' dangerouslySetInnerHTML={{ __html: data.node.profilDescription.childMarkdownRemark.html }} />
              <StaticImage src='../img/certifie.png' placeholder='blurred' alt='certification' className='f-right' width={100} height={100} />
            </div>
          </div>
        </div>
      </div>

      <div className='background1' ref={scrollTarget}>
        <div id='begin' className='container'>
          <div className='row'>
            <div className='col-md-6 division'>
              <h2 className='title'>L'HYPNOSE INTÉGRATIVE &reg;</h2>
              <p>C'est une méthode thérapeutique qui permet une évolution positive et rapide des troubles de comportement,
                tels que le stress, les addictions, les deuils, les traumatismes, les phobies.
              </p>
              <p>Les séances sont d'une durée moins longues que les séances Ericksoniennes, orientées résultats, elles ont pour objectif, l'amélioration de l'état mental et énergétique. D'ailleurs, dès la fin de la séance, vous pouvez déjà ressentir les bienfaits opérés.</p>
              <p>Et vous quels comportements souhaiteriez vous modifier ?</p>
            </div>

            <div className='col-md-6 division'>
              <h2 className='title'>HYPNOSE THÉRAPEUTIQUE</h2>
              <p>Que ce soit pour un deuil, une déprime, anxiété, phobie, je peux vous aider.</p>
              <p>Durant, la première séance, nous faisons connaissance, je pose des questions quant à votre vécu pour compléter l'anamnèse.</p>
              <p>Suite à cela, mise en place d'une stratégie d'hypnose intégrative &reg;.</p>
              <p>La séance se fait aussitôt, sollicitations de l'inconscient afin d'en obtenir des réponses. Phase de retour à l'ici et maintenant. Petit retour sur la séance. Prise du prochain rendez-vous.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4 division'>
            <h2 className='title'>TABAC</h2>
            <p>Ma méthode est unique mêlant hypnose conversationnelle, hypnose intégrative, et chiapi technique d’acupuncture au laser pour un arrêt rapide, sûr et durable.</p>
            <p>L'arrêt se fait en une séance avec éventuellement une séance de confort. Plus d’info….</p>
          </div>

          <div className='col-md-4 division'>
            <h2 className='title'>PRÉPARATION MENTALE</h2>
            <p>Préparation d'un séminaire, un examen (étudiants, bac, brevet...), épreuves sportives, spectacle. L'hypnose intégrative permet de puiser dans les ressources internes par un procédé unique et personnalisé afin d'exceller dans ses objectifs. </p>
          </div>

          <div className='col-md-4 division'>
            <h2 className='title'>POIDS</h2>
            <p>Hyperphagie prandiale, compulsion prandiale, compulsions nocturnes, grignotages pathologiques, boulimie, anorexie.</p>
            <p>Vous souhaitez perdre du poids ? Malgré tous les régimes vous n'y arrivez pas ?</p>
            <p>Vous sentez que vous souffrez d'un trouble du comportement lié à la nourriture, ne cherchez pas.</p>
            <p>L'hypnose intégrative peut vous aider.</p>
            <p>La méthode est simple et efficace, pour arriver à vaincre ce trouble, prévoyez 4 à 6 séances.</p>
            <p>L'hypnose thérapeutique ne se substitut pas à un avis médical ou un traitement médicamenteux.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
